<template>
  <div class="color-pallet mt-2">
    <div v-if="isDraggable" class="d-flex mt-2 sortable-container">
      <Sortable
        id="colors"
        class="colors"
        item-key="id"
        tag="div"
        :list="colors"
        :options="sortableOptions"
        @end="onUpdate"
      >
        <template #item="{ element, index }">
          <color-picker-box
            :key="element.id"
            :value="element.color"
            :is-draggable="isDraggable"
            @changed="(newColor) => colorChanged(index, newColor)"
            @removed="() => removeColor(index)"
          />
        </template>
      </Sortable>
    </div>
    <div v-else class="colors mt-2 d-flex">
      <color-picker-box
        v-for="(c, index) of colors"
        :key="c.id"
        :value="c.color"
        @changed="(newColor) => colorChanged(index, newColor)"
        @removed="() => removeColor(index)"
      />
      <ion-button class="d-flex color-btn" color="light" @click="addColor">
        <i class="ti-plus" />
      </ion-button>
    </div>
    <div class="d-inline-block reorder-button mr-2 mt-2" @click="toggleDraggable">
      <ion-custom-button :color="isDraggable ? 'secondary' : 'primary'">{{
        isDraggable ? 'Reordering' : 'Reorder'
      }}</ion-custom-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nanoid } from 'nanoid';
import { storyWorldCreatorStore } from '@/shared/pinia-store/storyworld-creator';
import ColorPickerBox from '@/shared/components/color-picker-box.vue';

const { setWorldColorsEditable, worldColorsEditable } = storyWorldCreatorStore();

const colors = worldColorsEditable;
const isDraggable = ref(false);

const sortableOptions = {
  handle: '.color-handle',
  animation: 150,
};

const onUpdate = (e: any) => {
  const toModify = [...colors.value];
  toModify.splice(e.newIndex, 0, toModify.splice(e.oldIndex, 1)[0]);
  setWorldColorsEditable(toModify.filter((item) => item));
};

const toggleDraggable = () => {
  isDraggable.value = !isDraggable.value;
};

const colorChanged = (index: number, color: string) => {
  colors.value[index].color = color;
  setWorldColorsEditable(colors.value);
};

const removeColor = (index: number) => {
  colors.value.splice(index, 1);
  setWorldColorsEditable(colors.value);
};

const addColor = () => {
  const toAdd = { color: '#FFF', id: nanoid(6) };
  if (colors.value) {
    colors.value.push(toAdd);
    setWorldColorsEditable(colors.value);
  } else {
    setWorldColorsEditable([toAdd]);
  }
};
</script>

<style lang="sass" scoped>
.colors
  flex-wrap: wrap
  ::v-deep
    .colorr
      width: 85px
      height: 85px
    .box, .color, .inner
      background: transparent !important
      width: 85px
      height: 85px
.sortable-container > div
  display: flex
.color-btn
  width: 85px
  height: 85px
  margin: 0 !important
  --border-radius: 0 !important
.reorder-button
  width: 33%
  ::v-deep
    .button
      width: 100%
      --border-radius: 8px !important
      --width: 100px
      height: 35px
      font-size: 13px !important
      user-select: none !important
</style>
