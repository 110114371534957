<template>
  <div class="story-world-actions position-relative">
    <div class="justify-content-center d-flex">
      <ion-button
        v-if="showDraftPrivacy"
        class="btn mr-3 save-draft-button"
        :disabled="action === 'saveAsDraft' && loading"
        @click="doSaveAsDraft"
      >
        <ChLoading size="sm" v-if="action === 'saveAsDraft' && loading" />
        <div v-else class="white">Save as Draft</div>
      </ion-button>
      <ion-button
        v-if="get(user, 'is_email_verified', true) || storyworld?.privacy === 'M'"
        class="btn"
        :disabled="action === 'publish' && loading"
        @click="publishStoryWorld"
      >
        <ChLoading size="sm" v-if="action === 'publish' && loading" />
        <div v-else>{{ actionName }}</div>
      </ion-button>
      <VerificationButton v-else />
    </div>
    <span v-if="!isNew" class="d-flex justify-content-center mt-4">
      <span class="delete-btn" @click="doDelete">Delete</span>
    </span>
  </div>
</template>

<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import { toast } from '@/shared/native';
import { storyWorldCreatorStore, useStoryWorld } from '@/shared/pinia-store/storyworld-creator';
import { useImageStore } from '@/shared/pinia-store/images';
import constants from '@/shared/statics/constants';
import { isYoutubeVideoLinkValid } from '@/shared/utils/string';
import VerificationButton from '@/shared/components/VerificationButton.vue';
import { authStore } from '@/shared/pinia-store/auth';
const { user } = authStore();

const imageStoreToWatch = useImageStore();
const route = useRoute();
const loading = ref(false);
const action = ref('');
const {
  storyworld,
  saveAsDraft,
  autosaveDraft,
  autosaveDraftNew,
  autosaveWorld,
  deleteWorld,
  deleteWorldDraft,
  publish,
  autosaveEnabled,
  isDirty,
  isDraft,
  markIsDone,
} = storyWorldCreatorStore();
const id = computed(() => storyworld?.value?.id);
const isNew = computed(() => !id.value);
const stopWatch = ref(null as any);
const stopWatchImg = ref(null as any);

const spotifyLink = computed(() => {
  return storyworld.value?.extra.spotify_link as any;
});

const actionName = computed(() => {
  return storyworld?.value?.privacy === 'M' ? 'Save' : 'Publish';
});

const _autosave = async () => {
  if (!autosaveEnabled.value) return;
  if (!isDirty.value) return;
  else if (isDraft.value) {
    if (isNew.value) {
      autosaveDraftNew();
    } else {
      autosaveDraft();
    }
  } else autosaveWorld();
};
const callAutosave = debounce(_autosave, 3000, { leading: false, trailing: true });

const doDelete = async () => {
  loading.value = true;
  action.value = 'delete';
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to delete this world.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Delete',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-secondary',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      markIsDone();
      if (id.value) {
        if (route.name === 'draft-story-world' || isDraft.value) await deleteWorldDraft();
        else await deleteWorld();
      }
    }
  } catch (e) {
    toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
  }
};

const doSaveAsDraft = async () => {
  doPublish(true);
};

const saveDraft = saveAsDraft;

const publishStoryWorld = async () => {
  doPublish(false);
};

const doPublish = async (savingAsDraft = false) => {
  loading.value = true;
  action.value = savingAsDraft ? 'saveAsDraft' : 'publish';
  markIsDone();

  try {
    if (!storyworld.value?.name) {
      await toast.show('Story world name is required', 'nonative', 'danger');
      return;
    }

    for (let link of spotifyLink.value || []) {
      if (
        get(link, 'link') &&
        !constants.spotifyLinkRegex.test(get(link, 'link')) &&
        !isYoutubeVideoLinkValid(get(link, 'link'))
      ) {
        await toast.show(
          'Invalid music link. Please enter a Spotify playlist, album or track link, or a Youtube video link.',
          'nonative',
          'danger'
        );
        return;
      }
    }
    if (!savingAsDraft) await publish();
    else await saveDraft();

    await toast.show('Successfully saved.', 'nonative', 'success');
  } catch (e: any) {
    if (e.response?.status === 400 && e.response?.data.err_code === 'cannot_update') {
      toast.show('You cannot publish NSFW content.', 'nonative', 'danger');
    } else {
      await toast.show('Error saving world, please try again later', 'nonative', 'danger');
    }
  } finally {
    loading.value = false;
  }
};

const showDraftPrivacy = computed(() => {
  const { name } = useRoute();
  if (isDraft.value || name === 'draft-story-world' || (name === 'create-story-world' && isNew.value)) {
    return true;
  }
  return false;
});

onMounted(() => {
  const editorStoreToWatch = useStoryWorld();
  setTimeout(() => {
    stopWatch.value = watch(() => editorStoreToWatch.$state, callAutosave, { deep: true });
    stopWatchImg.value = watch(() => imageStoreToWatch.$state, callAutosave, { deep: true });
  }, 5000);
});
onBeforeUnmount(() => {
  if (stopWatch.value) stopWatch.value();
  if (stopWatchImg.value) stopWatchImg.value();
});
</script>

<style lang="sass" scoped>
.story-world-actions
  margin-bottom: 80px
  .btn
    --border-radius: 10px
    text-transform: unset
    min-width: 150px
.white
  color: white !important
.delete-btn
  background-color: transparent
  color: gray
  cursor: pointer
  padding: 0 0.5rem 0.5rem 0.5rem
.delete-btn:hover
  text-decoration: underline
.save-draft-button
  --background: #00b4c5 !important
</style>
