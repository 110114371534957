<template>
  <div class="text">
    <ion-modal
      :is-open="showModalForIndex != MODAL_STATES.HIDDEN"
      @ionModalDidDismiss="
        () => {
          showModalForIndex = MODAL_STATES.HIDDEN;
        }
      "
      @ionModalDidPresent="onModalPresent"
      :show-backdrop="true"
      class="select-modal"
      ref="pickerModal"
    >
      <div class="select-modal-content bg-white w-100">
        <ion-list class="scroll">
          <ion-item @click="openSubscribeModal($event, 'worldAddSection')" class="option">
            <PlusTag size="md" class="mr-2" /> <strong class="no-select">See 100+ more trait ideas</strong>
          </ion-item>
          <div v-for="(value, key) of worldAboutKeys" :key="key">
            <div v-if="showCategory(key)">
              <ion-item 
                v-if="toTitleSpaceCase(String(key))"
                @click="toggleCategory(key)"
                button
              >
                <ion-label class="pt-4">
                  <span class="bold trait-cat-title no-select">{{ toTitleSpaceCase(String(key)) }}</span>
                  <i :class="collapsedCategories[key] ? 'ti-angle-down' : 'ti-angle-up'" class="ml-2" />
                </ion-label>
              </ion-item>
              <div v-show="!collapsedCategories[key]">
                <div v-for="(item, inIndex) of value" :key="inIndex" class="option-container">
                  <ion-item
                    button
                    v-if="showKey(item)"
                    class="no-select option"
                    :class="{ 
                      bold: item === 'custom',
                      selected: keyIsInUse(item),
                      'current-selection': showModalForIndex >= 0 && worldAbout?.[showModalForIndex]?.key === item
                    }"
                    :value="item"
                    @click="optionSelected($event, item)"
                  >
                    <div>
                      <span :id="'p_' + item" class="bold modal-about-key">{{ toTitleSpaceCase(item) }}</span><br>
                      <small>{{storyWorldAboutExamples?.[item as string] || ''}}</small>
                    </div>
                    <span slot="end">
                      <span class="mr-3 position-relative" style="bottom:1px;" v-if="item === 'custom' && customCount">{{ customCount }}</span>
                      <i v-if="keyIsInUse(item)" class="ti-check bold mr-3" />
                      <i v-else class="ti-plus mr-3 bold"  />
                    </span>
                  </ion-item>
                </div>
              </div>
            </div>
          </div>
        </ion-list>
        <ion-footer class="ion-no-border">
          <ion-toolbar class="tbottom">
            <ion-button class="close-btn" size="large" expand="block" @click="showModalForIndex = MODAL_STATES.HIDDEN">
              <span class="no-select">Close</span>
            </ion-button>
          </ion-toolbar>
        </ion-footer>
      </div>
    </ion-modal>

    <ion-reorder-group :disabled="false" @ionItemReorder="handleReorder($event)">
      <div 
        v-for="(element, index) of worldAbout" 
        :key="element.id" 
        class="d-flex align-items-center one-row"
        :class="{ shiftup: !isMobSmallScreen || worldAbout.length > 8 }"
      >
        <ion-reorder slot="end" class="reorder-desktop">
          <i id="about-drag-handle" class="ti-menu clickable-item-hov mx-2 about-drag-icon" />
        </ion-reorder>
        <ReorderMobile :index="index" :total="worldAbout.length" class="reorder-mobile" @reorder="handleReorder" />
        <div class="data d-flex">
          <ion-button 
            class="mr-2 mt-0 selector" 
            :class="{ custom: element.isCustom }"
            @click="showModalForIndex = index" 
            color="light" 
            :disabled="randomizersLoading?.includes(index)"
          >
            <span class="key-button no-select selector-text"
              ><span style="font-size: 1.1rem; user-select: none !important">▾</span
              >{{ element.isCustom ? 'Custom' : toTitleSpaceCase(element.key) }}</span
            >
          </ion-button>
          <div class="rest d-flex flex-grow-1">
            <ion-input
              v-if="element.isCustom"
              class="c-input about-key mr-2"
              placeholder="Field Title"
              :value="element.key?.replace(delimitingCharacterRegex, '')"
              @ionInput="debouncedCommitAboutValueCustomKey($event, index)"
            />
            <div class="textarea-wrapper">
              <img
                v-if="element.key !== 'custom' && !element.isCustom && !randomizerExclude?.includes(element.key)"
                :srcset="
                  '/icons/d6-1x.png 1x, ' +
                  '/icons/d6-2x.png 2x, ' +
                  '/icons/d6-3x.png 3x'
                "
                alt="Randomizer"
                class="details-icon"
                :class="[
                  randomizersLoading?.includes(index) ? 'rloading' : 'clickable-item-hov',
                ]"
                @click="randomizeAboutValue(index)"
              />
              <ion-textarea
                auto-grow
                v-model="element.value"
                :class="{ 
                  'w-100': !element.isCustom,
                  'has-icon': !randomizerExclude?.includes(element.key) && !element.isCustom
                }"
                class="c-textarea flex-grow-1 details-text"
                :placeholder="storyWorldAboutExamples[element.key] || 'Details'"
                :disabled="randomizersLoading?.includes(index)"
                @ionInput="debouncedCommitAboutValue($event, index)"
                
              />
              
            </div>
          </div>
        </div>

        <ion-button class="inline-button clickable-item-hov ml-1" color="transparent" @click="deleteAboutField(element.key)">
          <i class="ti-trash" />
        </ion-button>
      </div>
    </ion-reorder-group>
    <ion-custom-button class="add-btn text-white w-100 mt-2" @click.self="addAboutField">Add More</ion-custom-button>
  </div>
</template>

<script lang="ts" setup>
import { nanoid } from 'nanoid';
import ReorderMobile from './ReorderMobile.vue';
import { storyWorldCreatorStore } from '@/shared/pinia-store/storyworld-creator';
import { toTitleSpaceCase } from '@/shared/utils/string';
import { openSubscribeModal } from '@/shared/utils/modals';
import { getWorldTraitExample } from '@/shared/actions/worlds';
import {
  delimitingCharacter,
  delimitingCharacterRegex,
  worldAboutKeys,
  worldAboutPremiumCategories,
  worldAboutPremiumKeys,
  storyWorldAboutExamples,
  randomizerExclude
} from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';
import { featureFlags } from '@/shared/config/feature-flags';

const MODAL_STATES = {
  HIDDEN: -1,
  MULTI: -2,
};

const { isMobSmallScreen } = useWindowSize();
const { worldAbout, setWorldAbout } = storyWorldCreatorStore();
const { userPro } = authStore();
const showModalForIndex = ref(-1);
const pickerModal = ref<HTMLElement | null>(null);
const randomizersLoading = ref<number[]>([]);
/*
randomizerConfirmSkipList: list of keys.
On load: empty list.
If a key is in this list, it means the user has already confirmed this field and that we should not confirm before randomizing it
If a key is not in this list, it means the user has not confirmed this field and we should confirm before randomizing it
Remove a key from this list if it is manually edited by the user (commitAboutValue())
*/
const randomizerConfirmSkipList = ref<string[]>([]);
const collapsedCategories = ref({}) as any;

defineProps({
  usedKey: {
    type: String,
    default: '',
  },
});

const optionSelected = (event: any, selectedKey: string) => {
  try {
    event.target.blur();
    (document.activeElement as HTMLElement)?.blur();
    const items = document.querySelectorAll('.option');
    items.forEach(item => {
      item.classList.remove('ion-activated');
    });
  } catch (e) {}

  if (selectedKey === 'custom' && worldAbout.value?.[showModalForIndex.value]?.isCustom && showModalForIndex.value !== MODAL_STATES.MULTI) {
    showModalForIndex.value = MODAL_STATES.HIDDEN;
    return;
  }
  if (worldAbout.value.map(({ key }) => key).includes(selectedKey)) {
    if (selectedKey !== 'custom') {
      if (!worldAbout.value.find(item => item.key === selectedKey)?.value && showModalForIndex.value === MODAL_STATES.MULTI) {
        setWorldAbout(worldAbout.value.filter(item => item.key !== selectedKey));
      }
      if (showModalForIndex.value !== MODAL_STATES.MULTI) {
        showModalForIndex.value = MODAL_STATES.HIDDEN;
      }
      return;
    }
  }

  const about = [...worldAbout.value];
  if (selectedKey === 'custom' && about[showModalForIndex.value]?.key.startsWith('custom')) return;
  if (about.map(({ key }) => key).includes(selectedKey) && selectedKey !== 'custom') return;

  const emptyCustomFieldCount = about.filter((item) => !item.key.replace(delimitingCharacterRegex, '')).length;
  // Get all existing custom field lengths to avoid duplicates
  const existingLengths = about
    .filter(item => item.key.match(delimitingCharacterRegex))
    .map(item => item.key.length);
  let length = emptyCustomFieldCount;
  let attempts = 0;
  const count = 100;
  if (length >= count) {
    alert('Maximum of 100 custom fields allowed per section.');
    return;
  }
  while (existingLengths.includes(length) && length < count) {
    length++;
    attempts++;
    if (attempts >= count) {
      alert('Encountered an error when adding a custom field');
      return;
    }
  }
  const invisCharsUniq = delimitingCharacter.repeat(length);
  if (showModalForIndex.value !== MODAL_STATES.MULTI) {
    about[showModalForIndex.value].key =
      selectedKey === 'custom' ? invisCharsUniq : selectedKey;
    setWorldAbout(about);
    showModalForIndex.value = MODAL_STATES.HIDDEN;
  } else {
    const newAboutItem = {
      key: selectedKey === 'custom' ? invisCharsUniq : selectedKey,
      value: '',
      isCustom: selectedKey === 'custom',
      id: nanoid(8),
    };
    about.push(newAboutItem);
    setWorldAbout(about);
  }
};

const onModalPresent = () => {
  if (showModalForIndex.value === MODAL_STATES.HIDDEN) return;
  if (showModalForIndex.value === MODAL_STATES.MULTI) return;
  const document = useDocument();
  if (pickerModal) {
    const key = worldAbout.value[showModalForIndex.value].key
    const selectedOption = document.value.getElementById('p_' + key);
    if (selectedOption) {
      // Find which category contains the selected key
      const category = Object.entries(worldAboutKeys).find(([_, values]) => 
        values.includes(key)
      )?.[0];
      
      // If found and category is collapsed, expand it
      if (category && collapsedCategories.value[category]) {
        collapsedCategories.value[category] = false;
        setTimeout(() => {
          selectedOption.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 250);
      } else {
        selectedOption.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      
    }
  }
}

const toggleCategory = (category: string) => {
  collapsedCategories.value[category] = !collapsedCategories.value[category];
};
const customCount = computed(() => worldAbout.value.filter((item) => item.isCustom).length);
const aboutKeysInUse = computed(() => worldAbout.value.map(({ key }) => key));
const keyIsInUse = (key: string) => aboutKeysInUse.value.includes(key);

const showKey = (key: string) => {
  const inPremium = worldAboutPremiumKeys.includes(key);

  if (!inPremium) return true;

  if (!featureFlags.pro && inPremium) return false;

  if (inPremium && userPro.value.moreabout) return true;
};

const showCategory = (cat: string) => {
  const inPremium = worldAboutPremiumCategories.includes(cat);

  if (!inPremium) return true;

  if (!featureFlags.pro && inPremium) return false;

  if (inPremium && userPro.value.moreabout) return true;
};

const randomizeAboutValue = async (index: number) => {
  if (worldAbout.value[index].value?.trim()) {
    if (randomizersLoading.value?.includes(index)) return;
    if (!randomizerConfirmSkipList.value?.includes(worldAbout.value[index].key)) {
      if (!confirm('Replace current text with random suggestion?')) {
        return;
      } else {
        randomizerConfirmSkipList.value.push(worldAbout.value[index].key);
      }
    }
  } else {
    randomizerConfirmSkipList.value = randomizerConfirmSkipList.value.filter((key) => key !== worldAbout.value[index].key);
  }
  randomizersLoading.value = [...randomizersLoading.value, index];
  try {
    const about = worldAbout.value;
    const key = about[index].key;
    if (key === 'custom') return;
    const ex = await getWorldTraitExample(key);
    about[index].value = (ex || '').trim();
    setWorldAbout(about);
  } catch (e) {} finally {
    randomizersLoading.value = randomizersLoading.value.filter((i) => i !== index);
  }
};

const deleteAboutField = (key: string) => {
  setWorldAbout(worldAbout.value.filter((item) => item.key !== key));
};
const freeWorldAboutKeys = computed(() => {
  return omit(worldAboutKeys, worldAboutPremiumCategories);
});
const aboutKeys = computed(() => worldAbout.value.map(({ key }) => key));
const allKeys = flatten(Object.values(worldAboutKeys));
const freeMainKeys = flatten(Object.values(freeWorldAboutKeys.value));

const nextKey = computed(() => {
  const freekeys = freeMainKeys.filter((key) => !worldAboutPremiumKeys.includes(key));
  const keys = (userPro.value.moreabout ? allKeys : freekeys).filter((key) => !aboutKeys.value.includes(key));
  return keys.length > 1 ? keys[1] : '';
});

const addAboutField = () => {
  showModalForIndex.value = MODAL_STATES.MULTI;
};

const commitAboutValue = (ev: CustomEvent, index: number) => {
  const mutatedAbout = worldAbout.value;
  mutatedAbout[index].value = ev.detail.value || '';
  setWorldAbout(mutatedAbout);
  randomizerConfirmSkipList.value = randomizerConfirmSkipList.value.filter((key) => key !== mutatedAbout[index].key);
};

const debouncedCommitAboutValue = debounce(commitAboutValue, 300);

const commitAboutValueCustomKey = (ev: CustomEvent, index: number) => {
  const mutatedAbout = worldAbout.value;
  const newKey = ev.detail.value.replace(delimitingCharacterRegex, '');
  const about = worldAbout.value;
  const selectedKey = ev.detail.value;
  if (newKey) {
    mutatedAbout[index].key = newKey.replace(delimitingCharacterRegex, '');
  } else {
    // get the number of empty custom fields already in about
    const emptyCustomFieldCount = about.filter((item) => !item.key.replace(delimitingCharacterRegex, '')).length;
    let key = selectedKey === 'custom' ? delimitingCharacter.repeat(emptyCustomFieldCount) : selectedKey;
    while (about.includes(key)) {
      key += delimitingCharacter;
    }
    mutatedAbout[index].key = key;
  }
  setWorldAbout(mutatedAbout);
};

const debouncedCommitAboutValueCustomKey = debounce(commitAboutValueCustomKey, 300);

const handleReorder = (ev: CustomEvent) => {
  const { from, to } = ev.detail;
  const mutatedAbout = worldAbout.value;
  mutatedAbout.splice(to, 0, mutatedAbout.splice(from, 1)[0]);
  setWorldAbout(mutatedAbout);
  try {
    ev.detail.complete();
    ev.stopPropagation();
  } catch (e) {}
};
</script>

<style lang="sass" scoped>
.close-btn
  width: 90%
  left: 0
  right: 0
  margin: auto
  margin-bottom: 0.25rem
.selector
  min-width: 150px !important
  width: 150px !important
  overflow-x: hidden
  overflow-y: hidden
  text-overflow: ellipsis
  padding-inline-start: 0 !important
  margin: 0
  min-height: 42px
  height: auto 
  max-height: 60px
.selector-text
  overflow: hidden
  text-overflow: ellipsis
.about-key
  width: 150px !important
  flex-shrink: 0 !important
  :deep(input)
    height: 60px !important
.reorder-mobile
  display: none !important
.reorder-desktop
  display: block
  align-self: flex-start
  margin-top: 20px
  i
    cursor: move !important
    -webkit-user-drag: element
@media(max-width: 768px)
  .reorder-desktop
    display: none !important
  .reorder-mobile
    display: block !important
    align-self: flex-start
    margin-top: 5px
  .selector
    width: 100% !important
    margin-bottom: 3px
    margin-right: unset !important
  .custom 
    margin-top: 0.2rem !important
  .details-text
    width: 100% !important
.one-row
  &:not(:first-of-type)
    margin-top: 6px !important
  &.shiftup
    margin-top: 6px !important
.data
  width: 100%

@media(max-width: 768px)
  .rest
    width: 100% !important
    flex-direction: column !important
  .c-input
    margin-bottom: 0.25rem
    margin-top: 1px !important
    :deep(input)
      height: 40px !important
    &:last-of-type
      margin-right: 0 !important
  .data
    width: 100%
    flex-direction: column !important
    .c-select, .c-input
      width: 100% !important
      margin-top: 5px
.add-btn
  margin-left: 32px
  width: calc(100% - 65px) !important
  ::v-deep
    .button
      height: 30px !important
      text-transform: unset !important
.c-input, .c-select, .c-textarea
  min-height: 18px !important
  height: auto !important
  border-radius: 8px !important
  border: none !important
  overflow: hidden
  ::v-deep
    input, textarea
      border: none !important
    .textarea-wrapper
      min-height: 18px !important
      height: auto !important
      display: flex !important
      flex-direction: column !important
    textarea, .native-textarea[auto-grow]
      box-sizing: border-box !important 
      line-height: 18px !important
      padding: 12px !important
      vertical-align: top !important
      flex: 1 !important
      min-height: 18px !important
      border-radius: 8px !important
      overflow: hidden
      height: auto !important
      &:empty
        min-height: 42px !important
.c-textarea
  ::v-deep
    min-height: unset !important
.one-input
  margin-bottom: 10px
  border-radius: 8px !important
  ::v-deep
    input, textarea
      border: 0 !important
    textarea
      padding: 12px
.scroll
  overflow-y: scroll !important
  height: calc(100vh - 70px - var(--safe-ios-margin) - calc(var(--safe-ios-margin)/3))
  border-bottom-left-radius: 0
  border-bottom-right-radius: 0
.option
  --ion-item-background: transparent
  --background-hover: #eee
  --background-activated: transparent !important
  --background-focused: transparent !important
  --ripple-color: transparent !important
  --highlight-height: 0
  --detail-icon-opacity: 0
  --detail-icon-display: none
  padding-top: 2px
  // Mobile/touch specific states
  @media (hover: none) and (pointer: coarse)
    &:active
      --ion-item-background: #eee !important
      opacity: 0.7
    &.ion-activated
      --ion-item-background: transparent !important
      --background: transparent !important
    &::part(native)
      --background: transparent !important
.dark
  ion-modal
    --ion-item-background: #333538 !important
  .option-container
    &:hover
      --background-hover: #666 !important
  .option
    &:hover
      // Touch states for dark mode
      @media (hover: none) and (pointer: coarse)
        &:active
          --ion-item-background: #666 !important
        &.ion-activated
          --ion-item-background: transparent !important
          --background: transparent !important
        &::part(native)
          --background: transparent !important
  .selected
    background: #666 !important
    color: gray !important
    opacity: 0.6
    // Only apply hover on non-touch devices
    @media (hover: hover)
      &:hover
        background: #ddd !important
        color: gray !important
.gray
  color: #aaa
.trait-cat-title
  font-size: 20px
.key-button
  white-space: normal
.select-modal
  --height: 100%
  --width: 100%
  --border-radius: 0
  @media(min-width: 599px) and (min-height: 767px)
    --height: 70%
    --width: 50%
    --border-radius: 12px
  @media (max-width: 598px)
    --border-radius: 0 !important
.select-modal-content
  display: flex
  flex-direction: column
  height: 100%

  ion-content
    flex: 1
    overflow-y: auto

.dark .tbottom
  :deep()
    --background: #333538 !important

.textarea-wrapper
  position: relative
  display: flex
  flex-grow: 1
  align-items: center
  margin-right: 0

.details-icon
  position: absolute
  left: 7px
  top: 14px
  aspect-ratio: 1
  max-width: 30px
  max-height: 30px
  z-index: 3

.details-text
  &.has-icon
    ::v-deep
      textarea
        padding-left: 45px !important
.rloading 
  opacity: 0.5
  transition: opacity 0.1s ease

.selected
  background: #ddd !important
  color: gray !important
.hot 
  opacity: 0.75
  font-size: 0.8rem
  display: inline-block
  position: relative
  bottom: 1px
small
  color: #444
  bottom: 3px
  position: relative
  line-height: 0.5rem !important
.dark small 
  color: #aaa
.white 
  color: white !important
.option-container 
  &:hover
    background: #ddd !important
    color: gray !important
.current-selection
  background: #7050B733 !important
.dark .current-selection
  background: #7050B755 !important
.dark .reorderable-section .c-input 
  border: none !important
.dark .c-input 
  background-color: unset !important
</style>
