<template>
  <div class="text">
    <ion-reorder-group :disabled="false" @ionItemReorder="handleReorder($event)">
      <div
        v-for="(element, index) of mutatedFeaturedIn"
        :key="element.id"
        class="d-flex align-items-center mt-2 wrapper one-row"
      >
        <ion-reorder slot="end" class="reorder-desktop">
          <i id="about-drag-handle" class="ti-menu clickable-item-hov mx-2 about-drag-icon" />
        </ion-reorder>
        <ReorderMobile
          :index="index"
          :total="mutatedFeaturedIn.length"
          class="reorder-mobile"
          @reorder="handleReorder"
        />
        <div class="d-flex data">
          <ion-select
            v-model="element.type"
            mode="md"
            class="c-select bg-white featured-in-key mr-2"
            placeholder="Select Type"
            @ionBlur="mutate"
          >
            <div v-for="(value, key) of featuredInTypes" :key="key">
              <ion-select-option class="disable-as-group-title" :class="{ no: !key }">
                {{ toTitleSpaceCase(key) }}
              </ion-select-option>
              <ion-select-option
                v-for="(item, index) of value"
                :key="index"
                :class="{ 'custom-keyword': item === 'custom' }"
                :value="item"
              >
                {{ toTitleSpaceCase(item) }}
              </ion-select-option>
            </div>
          </ion-select>

          <ion-input
            v-if="element.type === 'custom'"
            class="c-input about-key mr-2 w-50"
            :value="(element.key || '').replace(delimitingCharacterRegex, '')"
            placeholder="Field Title"
            @ionInput="debouncedCommitValueCustomKey($event, index)"
          />
          <ion-input v-model="element.title" class="c-input mr-2" placeholder="Enter Title" @ionBlur="mutate" />
          <ion-input v-model="element.link" class="c-input" placeholder="Enter Link" @ionBlur="mutate" />
        </div>

        <ion-button class="inline-button" color="transparent" @click="deleteFeaturedInField(index)">
          <i class="ti-trash" />
        </ion-button>
      </div>
    </ion-reorder-group>

    <ion-custom-button class="add-btn text-white w-100 mt-2" @click.self="addFeaturedInField()"
      >Add More</ion-custom-button
    >
  </div>
</template>

<script lang="ts" setup>
import ReorderMobile from './ReorderMobile.vue';
import { storyWorldCreatorStore } from '@/shared/pinia-store/storyworld-creator';
import { toTitleSpaceCase } from '@/shared/utils/string';

import { delimitingCharacter, delimitingCharacterRegex, featuredInTypes } from '@/shared/statics/constants';

const { changeInfoKey } = storyWorldCreatorStore();

const props = defineProps({
  usedKey: {
    type: String,
    default: '',
  },
  value: {
    type: Object,
    default: {},
  },
  config: {
    type: Object,
    default: {},
  },
  isTemplate: {
    type: Boolean,
    default: false,
  },
});

const ready = ref(false);
const featuredIn = toRef(props, 'value');
const config = toRef(props, 'value');
const usedKey = toRef(props, 'usedKey');
const isTemplate = toRef(props, 'usedKey');

const mutatedFeaturedIn = ref([] as any[]);
const mutatedConfig = ref({});

const deleteFeaturedInField = (index: number) => {
  mutatedFeaturedIn.value.splice(index, 1);
  mutate();
};

const handleReorder = (ev: CustomEvent) => {
  const { from, to } = ev.detail;
  mutatedFeaturedIn.value.splice(to, 0, mutatedFeaturedIn.value.splice(from, 1)[0]);
  try {
    ev.detail.complete();
    ev.stopPropagation();
  } catch (e) {}
  mutate();
};

const addFeaturedInField = () => {
  mutatedFeaturedIn.value.push({ id: uniqueId(), type: '', title: '', link: '', key: '' });
  mutate();
};

onMounted(() => {
  mutatedFeaturedIn.value = map(featuredIn.value, (item, index) => {
    return { id: index, type: item.type, title: item.title, link: item.link, key: item.key };
  });

  mutatedConfig.value = config;
});

const commitValueCustomKey = (ev: CustomEvent, index: number) => {
  const mutated = mutatedFeaturedIn.value;
  const newKey = ev.detail.value.replace(delimitingCharacterRegex, '');
  const featuredIn = mutatedFeaturedIn.value;
  const selectedKey = ev.detail.value;
  if (newKey) {
    mutated[index].key = newKey.replace(delimitingCharacterRegex, '');
  } else {
    // get the number of empty custom fields already in about
    const emptyCustomFieldCount = featuredIn.filter((item) => !item.key.replace(delimitingCharacterRegex, '')).length;
    let key = selectedKey === 'custom' ? delimitingCharacter.repeat(emptyCustomFieldCount) : selectedKey;
    while (featuredIn.includes(key)) {
      key += delimitingCharacter;
    }
    mutated[index].key = key;
  }
  mutatedFeaturedIn.value = mutated;
  mutate();
};

const debouncedCommitValueCustomKey = debounce(commitValueCustomKey, 300);

const mutate = () => {
  const featuredIn = mutatedFeaturedIn.value.filter(
    (field) => field.type && (isTemplate.value || (field.title && field.link))
  );
  changeInfoKey(usedKey.value, featuredIn);

  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
};
</script>

<style lang="sass" scoped>
.reorder-mobile
  display: none !important
.reorder-desktop
  display: block
@media(max-width: 500px)
  .reorder-desktop
    display: none !important
  .reorder-mobile
    display: block !important
.one-row
  &:not(:first-of-type)
    margin-top: 20px !important
@media(max-width: 500px)
  .data
    width: 100%
    flex-direction: column !important
    .c-select, .c-input
      width: 100% !important
      margin-top: 5px

.data
  width: 100%
.featured-in-key
  width: 125px !important
.inline-button
  --color: #333 !important
.add-btn
  margin-left: 32px
  width: calc(100% - 65px) !important
  ::v-deep
    .button
      height: 30px !important
      text-transform: unset !important
.c-input, .c-select
  height: 45px !important
  border-radius: 10px !important
  border: 2px solid #CCC !important
  width: unset
  flex-grow: 1
  ::v-deep
    input
      border: 0 !important
.one-input
  margin-bottom: 10px
  border-radius: 10px !important
  ::v-deep
    input, textarea
      border: 0 !important
    textarea
      padding: 12px
</style>
