<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <modals-layout :data="{ title: modalTitle }" class="layout" @dismiss-modal="dismissModal">
      <div>
        <div class="d-flex justify-content-end">
          <div>{{ mutatedWorldCharacters?.length || 0 }} selected</div>
        </div>
        <div class="mt-3">
          <div class="d-flex align-items-center">
            <i class="icon pl-2 ti-search position-absolute" />
            <ion-input
              class="c-input one-input"
              :value="searchValue"
              placeholder="Search your characters"
              @input="searchValue = $event.target.value"
            />
          </div>
        </div>
        <div class="mt-2">
          <div class="d-flex justify-content-end">
            <ion-button :color="buttonColor" class="reorder-button" @click="toggleReorder">
              {{ buttonContent }}
            </ion-button>
          </div>

          <Grid v-if="mutatedWorldCharacters?.length" scrollable>
            <Sortable
              item-key="id"
              tag="div"
              class="sorting"
              :list="mutatedWorldCharacters"
              :options="sortableOptions"
              :class="{ dov: isReordering }"
              @end="handleReorder"
            >
              <template #item="{ element: character, index }">
                <CharacterCard
                  :key="index"
                  class="selectable-grid-item"
                  :character="character"
                  :is-selected="isCharacterSelected(character)"
                  image-only
                  is-selectable
                  is-removable
                  :is-reorderable="isReordering"
                  @select="select(character, $event)"
                />
              </template>
            </Sortable>
          </Grid>
          <div v-else class="no-data">Select characters to reorder</div>
        </div>
      </div>

      <div class="main mt-1 d-flex flex-column overflow-auto">
        <div v-if="allCharacters.length" class="selectable-characters mb-3 d-flex align-items-center">
          <Grid :scrollSm="false">
            <CharacterCard
              v-for="(character, index) of presentedCharacters"
              :key="index"
              class="grid-item"
              :character="character"
              is-selectable
              :is-selected="isCharacterSelected(character)"
              limited
              @select="select(character, $event)"
            />
          </Grid>
        </div>
        <div v-else class="no-data">No characters</div>
      </div>
      <div class="d-flex mt-1">
        <ion-custom-button color="primary" class="mt-3 mr-2 action-btn" @click="save">Save</ion-custom-button>
        <ion-custom-button color="medium" class="mt-3 action-btn" @click="dismissModal">Cancel</ion-custom-button>
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts" setup>
import { Character, CharacterInWorld } from '@/shared/types/static-types';
import Grid from '@/shared/components/storage/Grid.vue';
import CharacterCard from '@/shared/components/storage/CharacterCard.vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
  },
  modalTitle: {
    type: String,
    default: 'Select characters',
  },
  allCharacters: {
    type: Array,
    default: () => [],
  },
  worldCharacters: {
    type: Array,
    default: () => [],
  },
});

const allCharacters = computed(() => props.allCharacters as Character[]);
const worldCharacters = computed(() => props.worldCharacters as CharacterInWorld[]);
const mutatedWorldCharacters = ref<CharacterInWorld[]>([]);

const handleReorder = (e: any) => {
  const { oldIndex, newIndex } = e;
  const element = mutatedWorldCharacters.value[oldIndex];
  mutatedWorldCharacters.value.splice(oldIndex, 1);
  mutatedWorldCharacters.value.splice(newIndex, 0, element);
};

const searchValue = ref('');

const sortableOptions = ref({
  handle: '.reorder-handle',
  animation: 150,
});

const emits = defineEmits(['dismissModal', 'save']);

const dismissModal = () => {
  emits('dismissModal');
};

const isCharacterSelected = (character: Character) => {
  return mutatedWorldCharacters.value.map(({ id }) => id).includes(character?.id!);
};

const toggleReorder = () => {
  isReordering.value = !isReordering.value;
};

const presentedCharacters = computed(() =>
  searchValue.value
    ? allCharacters.value.filter((ch) => ch.slug?.toLocaleLowerCase().includes(searchValue.value))
    : allCharacters.value
);

const select = (character: Character, isSelected: boolean) => {
  if (isSelected) {
    mutatedWorldCharacters.value.push(character as any);
    mutatedWorldCharacters.value = [...new Set(mutatedWorldCharacters.value)];
  }

  if (!isSelected) {
    mutatedWorldCharacters.value = mutatedWorldCharacters.value.filter(({ id }) => id !== character.id);
  }
};

const isReordering = ref(false);

const buttonContent = computed(() => (isReordering.value ? 'Save order' : 'Reorder'));
const buttonColor = computed(() => (isReordering.value ? 'success' : 'primary'));

const save = () => {
  emits('save', mutatedWorldCharacters);
  emits('dismissModal');
};

onMounted(() => {
  mutatedWorldCharacters.value = worldCharacters.value || [];
});

watch(worldCharacters, (val: CharacterInWorld[]) => {
  mutatedWorldCharacters.value = val;
});
</script>

<style lang="sass" scoped>
.no-data
  height: 76px
.sorting
  grid-gap: 6px
  display: flex
  &.dov
    overflow: hidden
.reorder-button
  text-transform: unset
  --border-radius: 6px
.selectable-grid-item
  width: 60px !important
  min-width: 60px !important
  &.sortable-chosen
    opacity: 0.7
.icon
  z-index: 22
ion-radio
  width: 30px
  height: 30px


ion-radio::part(container)
  border-radius: 8px
  border: 2px solid #ddd


ion-radio::part(mark)
  background: none
  transition: none
  transform: none
  border-radius: 0


ion-radio.radio-checked::part(container)
  background: #6815ec
  border-color: transparent


ion-radio.radio-checked::part(mark)
  width: 6px
  height: 10px

  border-width: 0px 2px 2px 0px
  border-style: solid
  border-color: #fff

  transform: rotate(45deg)
.color
  &:not(.selected)
    cursor: pointer
.modal-big
  font-size: 14px
.main
  height: calc(100% - 164px - 2rem - 55px - calc(var(--safe-ios-margin)/3) - calc(var(--safe-ios-margin)))
  overflow-x: hidden !important
.color
  border-radius: 20px
  height: 160px
  width: 40px
  &.selected
    border: 3px solid #999
.title
  font-weight: 700
  font-size: 16px !important
.modal-big
  --width: 616px
  --height: 80%
.profile-picture
  width: 160px
  height: 160px
  border-radius: 16px
  .round-button
    bottom: 0
    right: 10px
    --padding-start: 0
    --padding-end: 0
    width: 40px
    height: 40px
  img
    width: 100%
    height: 100%
    object-fit: cover
    width: 160px
    height: 160px
    border-radius: 16px

.social-media-button
  min-width: 33px
  width: 33px
  height: 33px
  color: #FFF
  --padding-start: 0
  --padding-end: 0
  visibility: visible !important

@media(max-width: 768px)
  .modal-big
    --width: 100%
    --height: 100%
.c-input
  ::v-deep
    input
      padding-left: 30px !important

@media(max-width: 615px)
  .first-row
    flex-direction: column
    .second-section
      margin-left: 0 !important
      margin-top: 1rem
    .first-section
      align-items: center !important
      .title
        align-self: flex-start
.action-btn
  width: 90px !important
  margin-top: 0 !important
  ::v-deep
    *
      text-transform: unset !important
    .button
      height: 40px !important
</style>
