<template>
  <div class="story-world-header position-relative">
    <div class="cover position-relative">
      <img loading="lazy" v-image :src="resizeUpload(cover, '800w')" class="image" />
      <ion-button class="edit-btn position-absolute btn-reposition" @click="changeCoverPopover">
        <i class="ti-pencil" />
      </ion-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ChangeCoverPopover from '../popovers/CoverPopover.vue';
import { popovers } from '@/shared/native';
import { storyWorldCreatorStore } from '@/shared/pinia-store/storyworld-creator';
import { resizeUpload } from '@/shared/utils/upload';

const { storyworld } = storyWorldCreatorStore();

const cover = computed(() => {
  return storyworld?.value?.cover_image;
});

const changeCoverPopover = (ev: CustomEvent) => {
  popovers.open(ev, ChangeCoverPopover, {}, 'create-popover');
};
</script>

<style lang="sass" scoped>
.btn-reposition
  top: 13px
  right: 20px
.edit-btn
  width: 52px
  height: 52px
  font-size: 25px
  --background: rgba(10, 9, 58, 0.7)
  --border-radius: 10px
.story-world-header
  color: black
  border-radius: 20px
  overflow: hidden
  .cover
    height: 370px
    overflow: hidden
    .image
      object-fit: cover
      width: 100%
      height: 100%
</style>
