<template>
  <div>
    <div v-if="loadingCover" class="loading d-flex flex-column align-center">
      <div class="px-3 py-2">Uploading</div>
      <ion-progress-bar type="indeterminate"></ion-progress-bar>
    </div>
    <div v-else>
      <UploadForm class="cover-uploader" :is-cover="true" @uploaded="handleChangeCover">
        <ion-item class="item-container pointer">
          <div class="d-flex">
            <i class="ti-layout-grid4-alt" />
            <div class="ml-2 menu-item d-flex align-items-center">Choose a photo</div>
          </div>
        </ion-item>
      </UploadForm>
      <ion-item v-if="cover" class="item-container d-flex pointer" @click="clearCover">
        <i class="ti-eraser" />
        <div class="ml-2 menu-item d-flex align-items-center">Clear Cover Picture</div>
      </ion-item>
    </div>
  </div>
</template>

<script lang="ts" setup>
import UploadForm from '@/shared/components/upload-form.vue';
import { storyWorldCreatorStore } from '@/shared/pinia-store/storyworld-creator';

const props = defineProps({
  instance: {
    type: Object,
  },
});

const instance = props.instance;
const isOpen = ref(false);
const cropImage = ref('');
const { storyworld, changeCover } = storyWorldCreatorStore();
const loadingCover = ref(false);

const handleChangeCover = (url: string) => {
  changeCover(url);
  if (instance) {
    instance.dismiss();
  }
  loadingCover.value = false;
};

const cover = computed(() => {
  return storyworld?.value?.cover_image || '';
});

const clearCover = () => {
  changeCover('');
  if (instance) {
    instance.dismiss();
  }
};
</script>

<style scoped lang="sass">
.pointer
  cursor: pointer
.bottom-button
  background-color: var(--ion-color-primary) !important
  color: white
  padding: 10px 15px
  width: 100%
  text-align: center
  border-radius: 12px
  font-size: 0.8rem

.bottom-button:hover
  opacity: 0.8

.bottom-button-container
  padding: 0 0.25rem 0 0.25rem !important

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important

hr
  margin-bottom: 0
</style>
