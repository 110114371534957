<template>
  <div class="text">
    <ion-input v-model="mutatedValue.title" class="c-input one-input" placeholder="Add your own section title" />
    <div class="editor">
      <Editor
        :key="rerenderEditorKey"
        v-model="mutatedValue.body"
        :api-key="`${tinyMCEKey}`"
        :init="{
          height: 350,
          relative_urls: false,
          menu: {
            insert: {
              title: 'Insert',
              items: 'image link media inserttable | charmap emoticons hr  | insertdatetime',
            },
          },
          menubar: 'edit view insert format tools table help',
          plugins:
            'quickbars advlist autolink lists link image charmap preview anchor searchreplace visualblocks code insertdatetime media table code help wordcount',
          toolbar:
            'undo redo | bold italic backcolor |  blocks | fontfamily |  \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help',
          contextmenu: false,
          quickbars_insert_toolbar: false,
          quickbars_selection_toolbar: 'bold italic underline strikethrough link',
        }"
        placeholder="Write something!"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storyWorldCreatorStore } from '@/shared/pinia-store/storyworld-creator';
import { mainStore } from '@/shared/pinia-store/main';

const { changeExtraKey } = storyWorldCreatorStore();

const props = defineProps({
  usedKey: {
    type: String,
    default: '',
  },
  value: {
    type: Object,
    default: {},
  },
});

const text = toRef(props, 'value');
const usedKey = toRef(props, 'usedKey');
const mutatedValue = reactive({ title: '', body: '', type: '' });
const {
  public: { tinyMCEKey },
} = useRuntimeConfig();
const rerenderEditorKey = ref(0);

const isDarkMode = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const mutate = () => {
  const change = {
    ...text.value,
    ...mutatedValue,
  };
  changeExtraKey(usedKey.value, change);
};

watch(isDarkMode, () => {
  rerenderEditorKey.value++;
});

watch(mutatedValue, mutate, { deep: true });

onMounted(() => {
  mutatedValue.title = text.value.title;
  mutatedValue.body = text.value.body;
  mutatedValue.type = text.value.type;
});
</script>

<style lang="sass" scoped>
.text
  .editor
    min-height: 200px !important
    ::v-deep
      .tox
        min-height: 200px !important
        border: 0
        border-radius: 10px
.one-input
  margin-bottom: 10px
  border-radius: 10px !important
  ::v-deep
    input, textarea
      border: 0 !important
    textarea
      padding: 12px
</style>
