<template>
  <div class="p-3">
    Order: <ion-input v-model="mutatedOrder" class="c-input mt-1" />
    <ion-button color="primary" class="btn w-100 mt-2" @click="send">Send</ion-button>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  instance: {
    type: Object,
  },
  order: {
    type: Number,
  },
});

const instance = props.instance;
const order = toRef(props, 'order');

const mutatedOrder = ref(0);

onMounted(() => {
  mutatedOrder.value = order.value || 0;
});

const send = () => {
  isOpen.value = false;
  if (instance) {
    instance.dismiss(mutatedOrder.value);
  }
};

const isOpen = ref(false);
</script>

<style scoped lang="sass">
.btn
  --border-radius: 10px
</style>
