<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <modals-layout :data="{ title: modalTitle }" class="layout" @dismiss-modal="dismissModal">
      <div>
        <div class="d-flex justify-content-end">
          <div>{{ mutatedWorldFolders?.length || 0 }} selected</div>
        </div>
        <div class="mt-3">
          <div class="d-flex align-items-center">
            <i class="icon pl-2 ti-search position-absolute" />
            <ion-input
              class="c-input one-input"
              :value="searchValue"
              placeholder="Search your folders"
              @input="searchValue = $event.target.value"
            />
          </div>
        </div>
        <div class="mt-2">
          <div class="d-flex justify-content-end">
            <ion-button :color="buttonColor" class="reorder-button" @click="toggleReorder">
              {{ buttonContent }}
            </ion-button>
          </div>

          <Grid scrollable>
            <Sortable
              item-key="id"
              tag="div"
              class="sorting"
              :class="{ dov: isReordering }"
              :list="mutatedWorldFolders"
              :options="sortableOptions"
              @end="handleReorder"
            >
              <template #item="{ element: folder, index }">
                <FolderCard
                  :key="index"
                  class="selectable-grid-item"
                  :folder="folder"
                  :is-selected="isFolderSelected(folder)"
                  image-only
                  is-selectable
                  is-removable
                  :is-reorderable="isReordering"
                  @select="select(folder, $event)"
                />
              </template>
            </Sortable>
          </Grid>
        </div>
      </div>

      <div class="main mt-1 d-flex flex-column overflow-auto">
        <div v-if="presentedFolders.length" class="selectable-folders mb-3 d-flex align-items-center">
          <Grid :scrollSm="false">
            <FolderCard
              v-for="(folder, index) of presentedFolders"
              :key="index"
              class="grid-item"
              :folder="folder"
              is-selectable
              bordered
              :is-selected="isFolderSelected(folder)"
              limited
              @select="select(folder, $event)"
            />
          </Grid>
        </div>
        <div v-else class="no-data">No folders</div>
      </div>
      <div class="d-flex mt-1">
        <ion-custom-button color="primary" class="mt-3 mr-2 action-btn" @click="save">Save</ion-custom-button>
        <ion-custom-button color="medium" class="mt-3 action-btn" @click="dismissModal">Cancel</ion-custom-button>
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts" setup>
import { Collection } from '@/shared/types/static-types';
import Grid from '@/shared/components/storage/Grid.vue';
import FolderCard from '@/shared/components/storage/FolderCard.vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
  },
  modalTitle: {
    type: String,
    default: 'Select folders',
  },
  allFolders: {
    type: Array,
    default: () => [],
  },
  worldFolders: {
    type: Array,
    default: () => [],
  },
});

const allFolders = computed(() => props.allFolders as Collection[]);
const worldFolders = computed(() => props.worldFolders as Collection[]);
const mutatedWorldFolders = ref<Collection[]>([]);

const handleReorder = (e: any) => {
  const { oldIndex, newIndex } = e;
  const element = mutatedWorldFolders.value[oldIndex];
  mutatedWorldFolders.value.splice(oldIndex, 1);
  mutatedWorldFolders.value.splice(newIndex, 0, element);
};

const searchValue = ref('');

const sortableOptions = ref({
  handle: '.reorder-handle',
  animation: 150,
});

const emits = defineEmits(['dismissModal', 'save']);

const dismissModal = () => {
  emits('dismissModal');
};

const isFolderSelected = (folder: Collection) => {
  return mutatedWorldFolders.value.map(({ id }) => id).includes(folder?.id!);
};

const toggleReorder = () => {
  isReordering.value = !isReordering.value;
};

const presentedFolders = computed(() =>
  searchValue.value
    ? allFolders.value.filter((ch) => ch.slug?.toLocaleLowerCase().includes(searchValue.value))
    : allFolders.value
);

const select = (folder: Collection, isSelected: boolean) => {
  if (isSelected) {
    mutatedWorldFolders.value.push(folder as any);
    mutatedWorldFolders.value = [...new Set(mutatedWorldFolders.value)];
  }

  if (!isSelected) {
    mutatedWorldFolders.value = mutatedWorldFolders.value.filter(({ id }) => id !== folder.id);
  }
};

const isReordering = ref(false);

const buttonContent = computed(() => (isReordering.value ? 'Save order' : 'Reorder'));
const buttonColor = computed(() => (isReordering.value ? 'success' : 'primary'));

const save = () => {
  emits('save', mutatedWorldFolders.value);
  emits('dismissModal');
};

onMounted(() => {
  mutatedWorldFolders.value = worldFolders.value || [];
});

watch(worldFolders, (val: Collection[]) => {
  mutatedWorldFolders.value = val;
});
</script>

<style lang="sass" scoped>
.sorting
  grid-gap: 6px
  display: flex
  &.dov
    overflow: hidden
.reorder-button
  text-transform: unset
  --border-radius: 6px
.selectable-grid-item
  width: 60px !important
  min-width: 60px !important
  &.sortable-chosen
    opacity: 0.7
.icon
  z-index: 22
ion-radio
  width: 30px
  height: 30px


ion-radio::part(container)
  border-radius: 8px
  border: 2px solid #ddd


ion-radio::part(mark)
  background: none
  transition: none
  transform: none
  border-radius: 0


ion-radio.radio-checked::part(container)
  background: #6815ec
  border-color: transparent


ion-radio.radio-checked::part(mark)
  width: 6px
  height: 10px

  border-width: 0px 2px 2px 0px
  border-style: solid
  border-color: #fff

  transform: rotate(45deg)
.color
  &:not(.selected)
    cursor: pointer
.modal-big
  font-size: 14px
.main
  height: calc(100% - 164px - 2rem - 65px)
  overflow-x: hidden !important
.color
  border-radius: 20px
  height: 160px
  width: 40px
  &.selected
    border: 3px solid #999
.title
  font-weight: 700
  font-size: 16px !important
.modal-big
  --width: 616px
  --height: 80%
.profile-picture
  width: 160px
  height: 160px
  border-radius: 16px
  .round-button
    bottom: 0
    right: 10px
    --padding-start: 0
    --padding-end: 0
    width: 40px
    height: 40px
  img
    width: 100%
    height: 100%
    object-fit: cover
    width: 160px
    height: 160px
    border-radius: 16px

.social-media-button
  min-width: 33px
  width: 33px
  height: 33px
  color: #FFF
  --padding-start: 0
  --padding-end: 0
  visibility: visible !important

@media(max-width: 768px)
  .modal-big
    --width: 100%
    --height: 100%
.c-input
  ::v-deep
    input
      padding-left: 30px !important

@media(max-width: 615px)
  .first-row
    flex-direction: column
    .second-section
      margin-left: 0 !important
      margin-top: 1rem
    .first-section
      align-items: center !important
      .title
        align-self: flex-start
.action-btn
  width: 90px !important
  margin-top: 0 !important
  ::v-deep
    *
      text-transform: unset !important
    .button
      height: 40px !important
</style>
