<template>
  <div v-if="ready" class="text">
    <div class="title">Add to folder</div>
    <p v-if="isDraft" class="mt-0"><small>(Will be added to folder after this draft is published)</small></p>
    <ion-select
      v-model="mutatedWorldCollections"
      mode="md"
      placeholder="Choose folders"
      :multiple="true"
      class="bg-white select pr-2 mt-3"
    >
      <ion-select-option v-for="collection in allCollections" :key="collection.id" :value="collection.id">{{
        collection.name
      }}</ion-select-option>
    </ion-select>
  </div>
</template>

<script lang="ts" setup>
import { storyWorldCreatorStore } from '@/shared/pinia-store/storyworld-creator';
import { getCharacterCollections } from '@/shared/actions/collections';
import { authStore } from '@/shared/pinia-store/auth';

const { user: loggedInUser } = authStore();

const { changeInfoKey, isDraft } = storyWorldCreatorStore();

const props = defineProps({
  usedKey: {
    type: String,
    default: '',
  },
  value: {
    type: Array,
    default: [],
  },
});

const ready = ref(true);
const world_collections = toRef(props, 'value');

const allCollections = ref([] as any[]);

const fetchUserCollections = async () => {
  try {
    const response = await getCharacterCollections(loggedInUser.value.id);
    allCollections.value = response;
  } catch (_e) {
    // await toast.show('Could not load collections. Please try again.', 'nonative', 'danger');
  }
};

onMounted(async () => {
  await fetchUserCollections();
  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
});

const mutatedWorldCollections = computed({
  get() {
    return world_collections.value;
  },
  set(val) {
    changeInfoKey('world_collections', val);
  },
});
</script>

<style lang="sass" scoped>
.title
  font-size: 16px
  font-weight: bold
.select
  border-radius: 10px
  padding: 0 10px
.one-input
  margin-bottom: 10px
  border-radius: 10px !important
  ::v-deep
    input, textarea
      border: 0 !important
    textarea
      padding: 12px
</style>
