<template>
  <div class="story-world-sections position-relative">
    <Sortable
      v-if="ready"
      id="sections"
      :key="`_${rerenderKey}`"
      :disabled="false"
      item-key="key"
      tag="div"
      :list="reorderedSections"
      :options="sortableOptions"
      @end="handleReorder"
    >
      <template #item="{ element, index }">
        <ReorderableSection
          v-if="hasValue(element.value, element.key, false)"
          :key="`_${element.key}`"
          class="mb-4"
          :title="titleGetter(element.key, element.value)"
          :index="index"
          :used-key="element.key"
        >
          <div v-if="element.key === 'description'" class="text">
            <BodyOnly
              :key="rerenderKey"
              :value="element.value"
              :used-key="element.key"
              placeholder="Design worlds no GPS can find. Yet."
            />
          </div>
          <div v-if="element.key === 'spotify_link'" class="text">
            <Music :value="element.value" :used-key="element.key" placeholder="Add Spotify or Youtube link" />
          </div>
          <div v-if="element.key === 'subworlds' || element.key === 'included_locations'" class="text">
            <locations :key="rerenderKey" :value="element.value" :used-key="element.key" />
          </div>
          <div v-if="element.value.type === 'text'" class="text">
            <Text :key="rerenderKey" :value="element.value" :used-key="element.key" />
          </div>

          <div v-if="element.key === 'colors'" class="text">
            <Colors :used-key="element.key" />
          </div>
          <div v-if="element.key === 'visual_collections_page'" class="text">
            <InlineGallery
              :is-editor="true"
              :parent-type="currentWorldType"
              :parent-id="storyworld?.id"
              @touched="markAsDirty"
              :used-key="'world_gallery'"
              key="inline-gallery-editorsw"
            />
          </div>
          <div v-if="element.key === 'about'" class="text">
            <About :used-key="element.key" />
          </div>
          <div v-if="element.key === 'featuredin'" class="text">
            <FeaturedIn :value="element.value" :used-key="element.key" />
          </div>
          <div v-if="element.key === 'character_worlds'" class="text">
            <Characters :value="element.value" :used-key="element.key" />
          </div>
          <div v-if="element.key === 'featured_collections'" class="text">
            <Folders :value="element.value" :used-key="element.key" />
          </div>
        </ReorderableSection>
      </template>
    </Sortable>
  </div>
</template>

<script lang="ts" setup>
import ReorderableSection from './ReorderableSection.vue';
import Colors from './Colors.vue';
import FeaturedIn from './FeaturedIn.vue';
import Text from './Text.vue';
import BodyOnly from './BodyOnly.vue';
import Music from './Music.vue';
import About from './About.vue';
import Locations from './Locations.vue';
import Characters from './Characters.vue';
import Folders from './Folders.vue';
import InlineGallery from '@/shared/components/Gallery/InlineGallery.vue';
import { possibleExtraSectionsLabelMap, possibleSectionsLabelMap } from '@/shared/statics/story-world';
import { capitalizeFirstLetter } from '@/shared/utils/string';
import { storyWorldCreatorStore } from '@/shared/pinia-store/storyworld-creator';
import { unreorderableCreatorKeys } from '@/shared/statics/constants';
import { mainStore } from '@/shared/pinia-store/main';
const { changeInfoKey, storyworld, markAsDirty } = storyWorldCreatorStore();
const ready = ref(true);
const route = useRoute();

const titleGetter = (key: string, value: any) => {
  return possibleSectionsLabelMap[key] || possibleExtraSectionsLabelMap[key] || capitalizeFirstLetter(value.type) || '';
};

const currentWorldType = computed(() => {
  if (route.name === 'create-social-space' || route.name === 'edit-social-space') {
    return 'world';
  }
  if (!storyworld.value?.id || route.name === 'world-draft-details') {
    return 'world_draft';
  }
  return 'world';
});

const hasValue = (value: any, key: any, display: any) => {
  if (key === 'extra') return false;
  if (key === 'included_locations') return false;
  if (key === 'spotify_link' && display) return false;
  else return value || value === '';
};

const sortableOptions = {
  handle: '.section-handle',
  animation: 150,
};

const rerenderKey = ref(0);

const isDarkMode = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

watch(isDarkMode, () => {
  rerenderKey.value++;
});

const possibleSections = computed(() => {
  return omit(storyworld?.value, unreorderableCreatorKeys as string[], 'order', 'included_locations');
});

const computedOrder = computed(() => {
  return storyworld?.value?.extra.order || Object.keys(possibleSections.value);
});

const reorderedSections = computed(() => {
  const order: string[] = computedOrder.value;
  const reorderedSectionsList = order.map((key: any) => {
    return {
      key,
      value: !hasValue(possibleSections.value[key], key, true)
        ? possibleSections.value.extra[key]
        : possibleSections.value[key],
    };
  }) as { key: string; value: any }[];
  return reorderedSectionsList;
});

const handleReorder = (e: any) => {
  const toModify = storyworld?.value?.extra.order || Object.keys(possibleSections.value);
  toModify.splice(e.newIndex, 0, toModify.splice(e.oldIndex, 1)[0]);
  changeInfoKey(
    'order',
    toModify.filter((item: any) => item)
  );
  rerenderKey.value++;
};
</script>

<style lang="sass" scoped>
.story-world-sections
  .text
    .editor
      height: 200px
      ::v-deep
        .tox
          height: 200px !important
          border: 0
          border-radius: 10px
    .one-input
      margin-bottom: 10px
      border-radius: 10px !important
      ::v-deep
        input, textarea
          border: 0 !important
        textarea
          padding: 12px
</style>
