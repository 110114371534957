<template>
  <div v-if="ready" class="text world-editor-select">
    <div class="d-flex justify-content-end">
      <ion-button class="edit-btn" @click="isOpen = true">{{ buttonText }}</ion-button>
    </div>
    <div v-if="worldFolders?.length">
      <Grid :lg="3" :md="3" :sm="2" :scrollSm="false">
        <FolderCard v-for="(folder, index) of worldFolders" :key="index" class="grid-item" :folder="folder" />
      </Grid>
    </div>
    <div v-else class="no-data">No folders in this world yet</div>
    <SelectedFoldersModal
      :all-folders="allFolders"
      :world-folders="worldFolders"
      :is-open="isOpen"
      @dismiss-modal="isOpen = false"
      @save="save"
    />
  </div>
</template>

<script lang="ts" setup>
import SelectedFoldersModal from '../modals/SelectedFoldersModal.vue';
import { Collection } from '@/shared/types/static-types';
import { storyWorldCreatorStore } from '@/shared/pinia-store/storyworld-creator';
import { getCharacterCollections } from '@/shared/actions/collections';
import { authStore } from '@/shared/pinia-store/auth';
import Grid from '@/shared/components/storage/Grid.vue';
import FolderCard from '@/shared/components/storage/FolderCard.vue';

const { user } = authStore();

const { changeInfoKey, storyworld } = storyWorldCreatorStore();

const props = defineProps({
  usedKey: {
    type: String,
    default: '',
  },
  value: {
    type: Array,
    default: () => [],
  },
});

const isOpen = ref(false);

const ready = ref(true);
const usedKey = toRef(props, 'usedKey');

const buttonText = computed(() => (worldFolders.value?.length ? 'Edit/Reorder' : 'Add folders'));

const save = (mutatedWorldFolders: Collection[]) => {
  changeInfoKey(usedKey.value, mutatedWorldFolders);
};

const allFolders = ref([] as any[]);

const fetchFolders = async () => {
  try {
    const response = await getCharacterCollections(user.value.id);
    allFolders.value = response;
  } catch (_e) {}
};

onMounted(async () => {
  await fetchFolders();
  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
});

const worldFolders = computed(() => storyworld.value?.featured_collections as Collection[]);
</script>

<style lang="sass" scoped>
.edit-btn
  --border-radius: 8px
  text-transform: unset
</style>
