<template>
  <div class="d-flex align-items-center flex-column reorder-mobile mr-2">
    <ion-button class="btn" :class="{ disabled: index === 0 }" :disabled="index === 0" color="transparent" @click="up">
      <i class="ti-angle-up click-feedback" />
    </ion-button>
    <div class="order reorder-order d-flex align-items-center justify-content-center">
      <span v-if="index < 99">{{ leadingZero(index + 1) }}</span>
      <span v-else>{{ index + 1 }}</span>
    </div>
    <ion-button
      class="btn"
      :class="{ disabled: index + 1 === total }"
      :disabled="index + 1 === total"
      color="transparent"
      @click="down"
    >
      <i class="ti-angle-down click-feedback" />
    </ion-button>
  </div>
</template>

<script lang="ts" setup>
import { leadingZero } from '@/shared/utils/number';

const emit = defineEmits(['reorder']);

const props = defineProps({
  index: {
    type: Number,
    default: 0,
  },
  total: {
    type: Number,
    default: 0,
  },
});

const index = toRef(props, 'index');
const total = toRef(props, 'total');

const up = () => {
  const data = {
    detail: {
      from: index.value,
      to: index.value - 1,
    },
    complete: () => {},
  };
  emit('reorder', data);
};

const down = () => {
  const data = {
    detail: {
      from: index.value,
      to: index.value + 1,
    },
    complete: () => {},
  };
  emit('reorder', data);
};
</script>

<style lang="sass" scoped>
.reorder-mobile
  .order
    width: 30px
    height: 30px
    min-width: 30px
    border-radius: 5px
    margin: auto
    background-color: #fff
  .btn
    width: 30px
    height: 30px
    border-radius: 5px
    color: black
  .disabled
    opacity: 0.4
    pointer-events: none
</style>
