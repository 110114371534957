<template>
  <div class="story-world-settings position-relative">
    <div>
      <div class="header">
        Mark this world as mature
        <a
          class="link"
          target="_blank"
          href="https://docs.google.com/document/d/1xSdAdkRj7n8BfJuz0KKPiM2IJTi8MiAVePruZqr4Gag/edit#heading=h.sqkkh44ifgqo"
          >(see content guidelines)</a
        >
      </div>
      <ion-radio-group v-model="isNSFW" class="d-flex mt-3 ml-2" mode="md">
        <div @click="() => (changeColor = true)" class="d-flex align-items-center">
          <ion-radio
            :disabled="
              route.currentRoute.value.name === 'draft-story-world'
                ? false
                : storyworld?.privacy === 'M'
                ? false
                : route.currentRoute.value.name === 'edit-world'
                ? !(user.is_nsfw || user.show_nsfw)
                  ? true
                  : !user.is_nsfw && !user.show_nsfw && isNSFW
                : !(user.is_nsfw || user.show_nsfw)
            "
            class="mr-1"
            :value="true"
          />Yes
        </div>
        <div class="d-flex align-items-center ml-5">
          <ion-radio
            :disabled="
              route.currentRoute.value.name === 'draft-story-world'
                ? false
                : storyworld?.privacy === 'M'
                ? false
                : route.currentRoute.value.name === 'edit-world'
                ? !(user.is_nsfw || user.show_nsfw)
                  ? true
                  : !user.is_nsfw && !user.show_nsfw && isNSFW
                : !(user.is_nsfw || user.show_nsfw)
            "
            :value="false"
            class="mt-1 mr-1"
          />No
        </div>
      </ion-radio-group>
      <div
        v-if="
          route.currentRoute.value.name === 'draft-story-world'
            ? false
            : storyworld?.privacy === 'M'
            ? false
            : !(user.is_nsfw || user.show_nsfw)
        "
        class="d-flex align-items-center p-2 my-2"
        style="height: 30px"
        :style="changeColor ? `background: #ffc409 !important` : ''"
      >
        <ion-icon class="mr-1" style="font-size: 12px !important" :icon="warningOutline" />
        <p style="font-size: 10px; color: grey" :style="changeColor ? `font-weight: bold` : ''">
          You must turn on “show mature content” and confirm you’re 18+ in
          <router-link :style="changeColor ? `font-weight: bold` : ''" to="/account/user-settings" target="_blank">
            <strong class="clickable-item-hov">Content Settings</strong>
          </router-link>
          to share mature content
        </p>
      </div>
    </div>
    <hr class="my-4" />
    <Transition name="slide-fade" mode="out-in">
      <div>
        <div class="d-flex justify-content-between align-items-center clickable-item-hov" @click="actionClicked">
          <div class="header">Advanced Settings</div>
          <div></div>
          <div class="clickable-item-hov">
            <i class="ti-angle-down mr-1 toggle-icon" v-if="isSectionCollapsed" />
            <i class="ti-angle-up mr-1 toggle-icon" v-else />
          </div>
        </div>

        <div class="px-2 mt-3" v-if="!isSectionCollapsed">
          <Collections :value="worldCollections" />

          <div class="text mt-3">
            <div class="title bold mb-3">Trigger warning</div>
            <BodyOnly
              :key="rerenderKey"
              :value="triggerwarning"
              placeholder="(Optional) This is a pop-up warning for sensitive or triggering topics that are on your world's page"
              used-key="triggerwarning"
            />
          </div>
        </div>
      </div>
    </Transition>
    <hr class="my-4" />
    <div>
      <div class="header">Privacy</div>
      <div v-if="!get(user, 'is_email_verified', true)" class="d-flex align-items-center mt-2">
        <ion-icon class="mr-1" style="font-size: 12px !important" :icon="warningOutline" />
        <p style="font-size: 10px; color: grey">Verify your email to post publicly.</p>
      </div>
      <div
        v-else-if="isNSFW && !user.is_nsfw && !user.show_nsfw && route.currentRoute.value.name !== 'draft-story-world'"
        class="d-flex align-items-center mt-2"
      >
        <ion-icon class="mr-1" style="font-size: 12px !important" :icon="warningOutline" />
        <p style="font-size: 10px; color: grey">
          You can not post mature content publicly until you confirm you're 18+ in
          <router-link to="/account/user-settings" target="_blank">
            <strong class="clickable-item-hov">Content Settings</strong>
          </router-link>
        </p>
      </div>
      <ion-radio-group v-model="privacy" class="d-flex flex-column ml-2" mode="md">
        <div class="d-flex align-items-center mt-3">
          <ion-radio value="M" class="mr-1 clickable-item-hov" />
          <div class="d-flex flex-column">
            <div class="bold clickable-item-hov" @click="setPrivacy('M')">Private</div>
            <div class="msg">🔒 Upload your story world for personal use. Only you can see your story world.</div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-4">
          <div @click="() => (get(user, 'is_email_verified', true) ? null : openVerificationModal())">
            <ion-radio
              :disabled="
                (
                  get(user, 'is_email_verified', true)
                    ? route.currentRoute.value.name === 'draft-story-world'
                      ? true
                      : user.is_nsfw || user.show_nsfw
                      ? true
                      : !user.is_nsfw && !user.show_nsfw && !isNSFW
                    : false
                )
                  ? false
                  : true
              "
              value="U"
              class="mr-1 clickable-item-hov"
            />
          </div>
          <div
            class="d-flex flex-column"
            :style="
              isNSFW && !user.is_nsfw && !user.show_nsfw && route.currentRoute.value.name !== 'draft-story-world'
                ? 'color: grey'
                : ''
            "
          >
            <div
              class="bold clickable-item-hov"
              @click="
                () =>
                  (
                    get(user, 'is_email_verified', true)
                      ? route.currentRoute.value.name === 'draft-story-world'
                        ? true
                        : user.is_nsfw || user.show_nsfw
                        ? true
                        : !user.is_nsfw && !user.show_nsfw && !isNSFW
                      : openVerificationModal()
                  )
                    ? setPrivacy('U')
                    : null
              "
            >
              Unlisted
            </div>
            <div class="msg">🔗 Only you and those with the link can see your story world's page.</div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-4">
          <div @click="() => (get(user, 'is_email_verified', true) ? null : openVerificationModal())">
            <ion-radio
              :disabled="
                (
                  get(user, 'is_email_verified', true)
                    ? route.currentRoute.value.name === 'draft-story-world'
                      ? true
                      : user.is_nsfw || user.show_nsfw
                      ? true
                      : !user.is_nsfw && !user.show_nsfw && !isNSFW
                    : false
                )
                  ? false
                  : true
              "
              class="mr-1 clickable-item-hov"
              value="P"
            />
          </div>
          <div
            class="d-flex flex-column"
            :style="
              isNSFW && !user.is_nsfw && !user.show_nsfw && route.currentRoute.value.name !== 'draft-story-world'
                ? 'color: grey'
                : ''
            "
          >
            <div
              class="bold clickable-item-hov"
              @click="
                () =>
                  (
                    get(user, 'is_email_verified', true)
                      ? route.currentRoute.value.name === 'draft-story-world'
                        ? true
                        : user.is_nsfw || user.show_nsfw
                        ? true
                        : !user.is_nsfw && !user.show_nsfw && !isNSFW
                      : openVerificationModal()
                  )
                    ? setPrivacy('P')
                    : null
              "
            >
              Public
            </div>
            <div class="msg">Set your story world out into the world! Everyone can see your story world's page.</div>
          </div>
        </div>
      </ion-radio-group>
    </div>
    <VerificationModal :is-open="verificationModal" @dismiss-modal="closeVerificationModal" />
  </div>
</template>

<script lang="ts" setup>
import Collections from './Collections.vue';
import BodyOnly from './BodyOnly.vue';
import { storyWorldCreatorStore } from '@/shared/pinia-store/storyworld-creator';
import { authStore } from '@/shared/pinia-store/auth';
import { mainStore } from '@/shared/pinia-store/main';
import { warningOutline } from 'ionicons/icons';
import VerificationModal from '@/shared/modals/VerificationModal.vue';
const { storyworld, changeInfoKey, dropdownCollapsed, collapseDropDown } = storyWorldCreatorStore();

const route = useRouter();
const { user } = authStore();

const rerenderKey = ref(0);
const changeColor = ref(false);

const verificationModal = ref(false);

const openVerificationModal = () => {
  verificationModal.value = true;
};

const closeVerificationModal = () => {
  verificationModal.value = false;
};

const isDarkMode = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

watch(isDarkMode, () => {
  rerenderKey.value++;
});
const actionClicked = () => {
  let tempSectionValue = isSectionCollapsed.value;
  tempSectionValue = !tempSectionValue;
  collapseDropDown(tempSectionValue);
};
const isSectionCollapsed = computed(() => {
  return dropdownCollapsed.value || false;
});
const isNSFW = computed({
  get() {
    return storyworld.value?.is_nsfw || false;
  },
  set(value) {
    changeInfoKey('is_nsfw', value);
  },
});
const privacy = computed({
  get() {
    return storyworld.value?.privacy || 'P';
  },
  set(value) {
    changeInfoKey('privacy', value);
  },
});

// watch(privacy, (value: any) => {
//   if (value !== 'M') {
//     changeInfoKey('is_nsfw', false);
//   }
// });

const setPrivacy = (value: string) => {
  changeInfoKey('privacy', value);
};

const triggerwarning = computed({
  get() {
    if (get(storyworld.value, 'triggerwarning')) return get(storyworld.value, 'triggerwarning');
  },
  set(value) {
    changeInfoKey('triggerwarning', value);
  },
});

const worldCollections = computed(() => {
  return storyworld.value?.world_collections;
});
</script>

<style lang="sass" scoped>
.dark .story-world-settings
  background-color: #17074C !important
  color: #FFF !important
.story-world-settings
  background: #E6E6E6
  border-radius: 20px
  padding: 24px
  margin-top: 24px
  color: #333333
  .select
    padding-right: 10px
  .header
    font-weight: bold
  .msg
    color: #333
    font-weight: 300
    font-size: 13px
.clickable-item-hov
  background: unset
</style>
